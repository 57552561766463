import { CaretDownOutlined, CaretUpOutlined, CopyOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Menu, Select, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional.js";
import { usePrefs } from "../utils/Prefs";
import { useSession } from "../utils/Session";
import BusinessContactInfo from "./BusinessContactInfo";
import Overlay from "./Overlay";
import QuickPick from "./QuickPick";

export default function Pubstock(props) {

    const { 
        want_store = true, 
        wrap = false, 
        title_suppliers = false, 
        updatePrice = () => { }, 
        isbn, 
        is_order = false, 
        selectSupplier, 
        price = "", 
        list_price_usa = "", 
        show_stores = false 
    } = props;

    const [session, setSession] = useSession();
    const [suppliers, setSuppliers] = useState((title_suppliers) ? title_suppliers : []);
    const [bm, setBM] = useState({ class_1: "" });
    const [stores, setStores] = useState([]);
    const [prefs, setPrefs] = usePrefs(); 

    const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    if (!show_stores) {
        moment.updateLocale('en', {
            relativeTime: {
                future: "in %s",
                past: "%s ",
                s: "a sec ago",
                m: "a min ago",
                mm: "%d min ago",
                h: "an hour ago",
                hh: "%d hours ago",
                d: "a day ago",
                dd: "%d days ago",
                M: "a month ago",
                MM: "%d months ago",
                y: "a year ago",
                yy: "%d years ago"
            }
        });
    } else {
        moment.updateLocale('en', {
            relativeTime: {
                future: "+%s",
                past: "%s",
                s: "1s",
                m: "1m",
                mm: "%dm",
                h: "1h",
                hh: "%dh",
                d: "1d",
                dd: "%dd",
                M: "1m",
                MM: "%dm",
                y: "1y",
                yy: "%dy"
            }
        });
    }

    ///i/nocover-120.png
    const getPubstock = () => {
        if (title_suppliers) {
            return;
        }
        apiCall("title/getPubstock", { isbn: isbn, want_store: want_store, want_supplier: true, want_bm_data: true }, (_status, _result) => {
            if (_status) {
                setSuppliers(addKeys(_result.suppliers));
                setStores(addKeys(_result.stores));
            }
        })
    }

    useEffect(getPubstock, [isbn]);

    const ColumnHelp = (props) => {
        return (
            <Tooltip title={props.title}>
                {props.children}
            </Tooltip>
        )
    }

    const drawOO = (_code) => {
        let msg = ""
        // eslint-disable-next-line default-case
        switch (_code) {
            case "ITO":
                msg = <>Import To Order<small><br />Must be imported, taking at least 2-6 weeks to get stock.</small></>
                break;
            case "Qwk":
                msg = <>Quick<small><br />Not stocked, but can get stock in less than 2 weeks.</small></>
                break;
            case "LOW":
                msg = <>LOW<small><br />Stock onhand is relatively low. Item may get backordered/cancelled.</small></>
                break;
            case "POD":
                msg = <>Print On Demand<small><br />Copies printed on demand.</small></>
                break;
            case "QPOD":
                msg = <>Quick Print On Demand<small><br />Copies printed on demand the same day.</small></>
                break;
            case "RP":
                msg = <>Reprinting<small><br />The book is being reprinted by the publisher.</small></>
                break;
            case "NYR":
                msg = <>Not Yet Received / Published<small><br />Not received by the wholesaler or published by the publisher.</small></>
                break;
            case "TOS":
                msg = <>Temporarily out of stock<small><br />Item will be in stock shortly.</small></>
                break;
            case "BOO":
                msg = <>Backorder Only<small><br />Title available as a special order. ETA unknown.</small></>
                break;
            case "Mgd":
                msg = <>Managed Title<small><br />Quantities shipped are managed manually.</small></>
                break;
        }
        if (msg) {
            return (<ColumnHelp title={msg}>{_code}</ColumnHelp>)
        }
        return _code;
    }

    const suppliers_columns = [
        {
            title: <small></small>, key: 'flag', width: "30px", dataIndex: 'flag', render: (e, f) => {
                return <><img src={getFlag(f.country)} /></>;
            }
        },
        {
            title: <div style={{ "textAlign": "left" }}><small>Suplr</small></div>, key: 'acct', dataIndex: 'acct', render: (e, f) => {
                return  <Overlay width={600} component={<div style={{"padding" : "20px"}}><strong>Contact</strong><Divider dashed style={{"margin" : "10px 0px"}} /><div className="shim" /><div style={{"margin" : "-8px"}}><BusinessContactInfo id={f.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{e}</a></Overlay>;
            }
        },
        {
            title: <small>O/H</small>, key: 'onhand', dataIndex: 'onhand', render: (e) => {
                return (e) ? <span style={{ "color": "#4F9818" }}>{e.toString()}</span> : ""
            }
        },
        {
            title: <small>O/O</small>, key: 'onorder', dataIndex: 'onorder', render: (e) => {
                return (e) ? <span style={{ "color": "#E77E21" }}>{drawOO(e)}</span> : ""
            }
        },
        { title: <small><ColumnHelp title="Prices are in vender's currency" >Price</ColumnHelp> </small>, key: 'supplier_price', dataIndex: 'supplier_price' },
        { title: <small><ColumnHelp title="Discounts shown reflect general trade bookseller levels." >%</ColumnHelp> </small>, key: 'discount', dataIndex: 'discount' },
        { title: <small>Rtrn</small>, key: 'returnable', dataIndex: 'returnable' },
        { title: <small>As of</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return (e) ? moment(e * 1000).fromNow() : "" } },
    ]

    // "onhand": "7",
    // "onoorder": "",
    // "province": "BC",
    // "city": "Kelowna",
    // "store_acct": "1677004",
    // "store_name": "Mosaic Books",
    // "asof": 1625849949,
    // "price": "$32.95"
    // E77E21 4F9818
    // price,oh,oo,prov,name,city,asof
    
    const store_columns = [
        {
            title: <div style={{ "textAlign": "left" }}><small>Name</small></div>, width: "200px", key: 'store_name', dataIndex: 'store_name', render: (e, f) => {
                return  <Overlay width={500} component={<div style={{"padding" : "20px"}}><strong>Contact</strong><Divider dashed style={{"margin" : "10px 0px"}} /><div className="shim" /><div style={{"margin" : "-8px"}}><BusinessContactInfo id={f.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{e}</a></Overlay>;
            },
            sorter: {
                compare: (a, b) => a.store_name.localeCompare(b.store_name)
            },
        },
        {
            title: <small>Price</small>, key: 'price', dataIndex: 'price',
            sorter: {
                compare: (a, b) => a.price > b.price,
            },
            render: (e) => {
                return (e === "not shared") ? "---" : e.replace("$", "");
            }
        },
        {
            title: <small>O/H</small>, width : "40px", key: 'onhand', dataIndex: 'onhand', render: (e) => {
                return (e) ? <span style={{ "color": "#4F9818" }}>{e.toString()}</span> : ""
            }
        },
        {
            title: <small>O/O</small>, width : "40px", key: 'onorder', dataIndex: 'onorder', render: (e) => {
                return (e) ? <span style={{ "color": "#E77E21" }}>{e.toString()}</span> : ""
            }
        },
        {
            title: <small>Prov</small>, key: 'province', dataIndex: 'province',
            sorter: {
                compare: (a, b) => a.province.localeCompare(b.province)
            }
        },
        {
            title: <div style={{ "textAlign": "left" }}><small>City</small></div>, key: 'city', width: "120px", dataIndex: 'city', render: (e) => {
                return <Tooltip title={<small>{e}</small>}><div style={{ "textAlign": "left", "maxWidth": "75px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }} >{e}</div></Tooltip>
            },
            sorter: {
                compare: (a, b) => a.city.localeCompare(b.city)
            }
        },
        { title: <small>As of</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return (e) ? moment(e * 1000).fromNow() : "" } },
    ]

    const getFlag = (_country) => {
        let flag = "https://cdn1.bookmanager.com/i/CA.png"
        switch (_country) {
            case "Canada":
                flag = "CA"
                break;
            case "United Kingdom":
                flag = "CA"
                break;
            case "USA":
                flag = "US"
                break;
            default:
                flag = "CA"
                break;
        }
        return "https://cdn1.bookmanager.com/i/" + flag + ".png";
    }

    const [expanded, setExpanded] = useState([]);

    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const drawChildTable = (_record) => {
        return (<BusinessContactInfo id={_record.id} />);
    }

    const [toggle, setToggle] = useState(false)

    const parseSuppliers = (count = false) => {
        let _qps = session.quick_picks.reduce((a, { supplier }) => {
            if (supplier) a.push(supplier);
            return a;
        }, []);
        let _cart = session.cart.find(item => item.isbn == isbn) || { suppliers: [] };
        _cart = _cart.suppliers.reduce((a, { location }) => {
            if (location) a.push(location);
            return a;
        }, []);
        let _first = []; // cart
        let _second = [] // quick picks
        let _third = [] // fav
        let _fourth = [] // rest
        suppliers.forEach(item => {
            if (_cart.includes(item.acct)) {
                _first.push(item);
            } else if (_qps.includes(item.acct)) {
                _second.push(item);
            } else if (item.priority === "Fav" || item.priority === "Private") {
                _third.push(item);
            } else {
                // check if more is selected
                _fourth.push(item);
            }
        });
        let _s = suppliers.reduce((a, { acct }) => {
            if (acct) a.push(acct);
            return a;
        }, []);
        _qps.forEach(item => {
            if (!_s.includes(item)) {
                _second.push({
                    acct: item
                })
            }
        })
        let res;
        let min = 0;
        if (_qps.length > 0) {
            res = _first.concat(_second);
            min = res.length;
        } else {
            res = _first.concat(_second).concat(_third);
            min = res.length;
        }
        if (toggle) {
            if (_qps.length > 0) {
                res = res.concat(_third).concat(_fourth)
            } else {
                res = res.concat(_fourth)
            }
        } else {
            if (res.length === 0) {
                // there is a chance there are no favs, quick picks or cart items. in this case show 3
                res = res.concat(_fourth.slice(0, 3));
            }
        }
        let max = _first.concat(_second).concat(_third).concat(_fourth).length;
        if (count) {
            return max - min;
        }
        if (wrap) {
            res = _first.concat(_second).concat(_third).concat(_fourth);
        }
        return res;
    }
    // Supl, oh, oo, sellpr, pm, SupPr, Disc, Cost, Asof, QP-Grp
    const groupMenu = (f) => {
        return (
            <div className="shadow" style={{ "maxHeight": "150px", "overflow": "auto", "boxShadow": "1px 1px 2px 2px #eee" }}>
                <Menu>
                    {groups.map(item => {
                        return (
                            <Menu.Item onClick={() => selectSupplier(f, false, false, item, true)} style={{ "padding": "0px 5px", "lineHeight": "16px", "height": "16px", "width": "40px" }} key={item}>
                                <small>{item}</small>
                            </Menu.Item>
                        )
                    })}
                </Menu>
            </div>
        )
    }

    const order_columns = [
        {
            title: <small>Supl</small>, key: 'flag', width: "30px", dataIndex: 'flag', render: (e, f) => {
                return <><Space size={3}><img src={getFlag(f.country)} /><a onClick={() => selectSupplier(f, false, true, "", true)} style={{ "fontSize": "11px" }}>{f.acct}</a></Space></>;
            }
        },
        {
            title: <small></small>, key: 'inc', width: "inc", dataIndex: 'inc', render: (e, f) => {
                return (
                    <>
                        <Select getPopupContainer={trigger => trigger.parentElement} className='ant-select-with-custom-arrow' onSelect={(letter) => selectSupplier(f, false, false, letter, true)} size="small" className="select-padding" style={{ "opacity": "0", "padding": "0px" }} dropdownClassName="tinyDropdown" bordered={false}>
                            {groups.map(item => {
                                return (<Select.Option style={{ "width": "30px" }} key={item} value={item}><small>{item}</small></Select.Option>)
                            })}
                        </Select>
                        <CopyOutlined style={{ "pointerEvents": "none", "marginLeft": "-18px", "color": "#1890ff" }} />
                    </>
                )
            }
        },
        {
            title: <small>OH</small>, key: 'onhand', dataIndex: 'onhand', render: (e, f) => {
                if (!f.onhand && !f.onorder) {
                    return <span style={{ "color": "#f12a46" }}>no stock</span>
                } else {
                    return (e) ? e.toString() : ""
                }
            }
        },
        { title: <small>OO</small>, key: 'onorder', dataIndex: 'onorder', render: (e) => { return (e) ? drawOO(e) : "" } },
        {
            title: <small>SellPr</small>, key: 'sell_price', dataIndex: 'sell_price', render: (e) => {
                return <a onClick={() => updatePrice(e)}>{e}</a>
            }
        },
        {
            title: <small>PM</small>, key: 'profit_margin', dataIndex: 'profit_margin', render: (e) => {
                return (e) ? <>{e}%</> : ""
            }
        },
        {
            title: <small><ColumnHelp title="Prices are in vender's currency" >SupPr</ColumnHelp> </small>, key: 'supplier_price', dataIndex: 'supplier_price', render: (e) => {
                return (<a onClick={() => updatePrice(e, true)}>{e}</a>)
            }
        },
        { title: <small><ColumnHelp title="Discounts shown reflect general trade bookseller levels." >Disc</ColumnHelp> </small>, key: 'discount', dataIndex: 'discount' },
        {
            title: <small>Exch</small>, key: 'exchange', dataIndex: 'exchange', render: (e) => {
                return (e != 1) ? <small>{e}</small> : ""
            }
        },
        { title: <small>Cost</small>, key: 'ex', dataIndex: 'cost' },
        { title: <small>As of</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return (e) ? moment(e * 1000).fromNow() : "" } },
        {
            title: <small>QP-Grp</small>, width: "66px", key: 'quick', dataIndex: 'quick', render: (e, f) => {
                return <QuickPick supplier={f.acct} />
            }
        }
    ]

    const show_more = parseSuppliers(true);

    const drawFooter = () => {
        return (
            <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px" }}>
                <div style={{ "float": "right" }}>
                    <Space>
                        <conditional.true value={((prefs.last_used))}>
                            <div style={{ "float": "right" }}><a onClick={() => drawLast(prefs.last_used)}><small>{prefs.last_used} (Last used)</small></a><small> </small></div>
                        </conditional.true>
                        <nobr><small><a onClick={() => updatePrice(price)}>${price} </a> CAD</small></nobr>
                        <nobr><small><a onClick={() => updatePrice(list_price_usa)}>${list_price_usa} </a> USD</small></nobr>
                    </Space>
                </div>
                <small>
                    <conditional.true value={(show_more && !wrap)}>
                        <a onClick={() => setToggle(!toggle)}>{(toggle) ? <>See less &nbsp;<CaretUpOutlined /></> : <>See {show_more} more &nbsp;<CaretDownOutlined /></>}</a>
                    </conditional.true>
                    <conditional.true value={(!show_more)}>
                    &nbsp;
                    </conditional.true>
                    <div style={{ "height": (wrap) ? "19px" : "2px" }} className="shim" />
                </small>
            </div>
        )
    }

    const drawLast = (_supp) => {
        let _find = parseSuppliers().find(item => item.acct === _supp);
        if (_find) {
            selectSupplier(_find)
        } else {
            selectSupplier({ acct: _supp });
        }
    }

    const drawHeader = (_header, _memory = true) => {
        return (
            <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px", "height": "22px", "lineHeight": "20px" }}>
                <Space>
                    <strong className="c"><small>{_header}</small></strong>
                </Space>
            </div>
        )
    }

    if (is_order) {
        if (parseSuppliers().length === 0) {
            return (<></>);
        }
        return (<>
            <Table
                expandable
                expandIcon={<></>}
                title={() => (wrap) ? drawHeader("Pubstock", true) : null}
                size="small"
                rowKey={"acct"}
                className={(props.class ? props.class : "")}
                columns={order_columns}
                dataSource={addKeys(parseSuppliers())}
                footer={() => drawFooter()}
                pagination={false}
                expandIconColumnIndex={-1}
                expandedRowKeys={expanded}
                onExpand={(e) => { return false; }}
                expandedRowRender={((record) => drawChildTable(record))}
            />
        </>)
    }

    return (<>
        <div style={{ "margin": "0 -20px" }} >
            <div>
                <Space size={0} align="start">
                    <conditional.true value={(!show_stores)}>
                        <div style={{ "margin": "0px 0px" }}>
                            <Table
                                expandable
                                size="small"
                                pagination={false}
                                columns={suppliers_columns}
                                scroll={{ y: 308 }}
                                style={{ "width": "589px", "border": "1px solid", "borderLeft": "6px solid" }}
                                className={"mediumTable bc"}
                                dataSource={suppliers}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={expanded}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </conditional.true>
                    <conditional.true value={(show_stores)}>
                        <div style={{ "margin": "0px 0px" }}>
                            <Table
                                expandable
                                size="small"
                                pagination={false}
                                scroll={{ y: 308 }}
                                columns={store_columns}
                                className={"mediumTable bc"}
                                style={{ "width": "589px", "border": "1px solid", "borderLeft": "6px solid" }}
                                dataSource={stores}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={expanded}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </conditional.true>
                </Space>
            </div>
        </div>
    </>
    )
}