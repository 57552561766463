import { Button, Checkbox, Divider, Form, message, Select, Space, Typography } from "antd";
import { useState, useEffect } from "react";

import { exceloptions } from "../../../data";
import { apiCall, apiDownload } from "../../../utils/Api";
import { useSession } from "../../../utils/Session"

export default function MultiSelectExcel(props) {
    const {
        close = () => { },
        isbns,
        setSelectedRowKeys = () => { },
    } = props

    const [sharedStores, setSharedStores] = useState([]);
    const [session, setSession] = useSession(); 

    const initOptions = exceloptions.concat([{
        label: "Include store data (OnHand, OnOrder, and Biz cart OnOrder only)",
        value: "show_ohoo",
        checked: false,
    }])

    

    const [options, setOptions] = useState(initOptions)
    const store = options.find(item => item.value === "show_ohoo") || { checked: false };


    const getSharedStores = () => {
        let api_object = {
            my_accounts: false,
            approved: true,
            pending: false,
            declined: false,
            potential: false,
        }
        apiCall("rep_control/getAll", api_object, (_status, _result) => {
            if (_status) {
                setSharedStores(_result)
            } else {
                message.error("Unable to select stores to work with, please contact Bookmanager to update your permissions/subscriptions")
            }
        })

    }

    useEffect(getSharedStores, [])

    const onFormFinish = (form_data) => {
        form_data["isbns_json"] = JSON.stringify(isbns);
        apiDownload(form_data, "title/getExcelOnIsbns")
        // setSelectedRowKeys([]);
        // close();
    }

    const onChange = (valid, _ind) => {
        let _options = [...options];
        _options[_ind].checked = valid;
        setOptions(_options);
    }

    const default_store = (session?.stores_working_with.length>0) ?  session.stores_working_with[0].contact_id : "";

    return (
        <>
            <div style={{ "padding": "0px" }}>
                <Typography.Title level={5}>Create Excel</Typography.Title>
                <Divider style={{ "marginBottom": "10px" }} />
                <p>To export these titles to Excel format, choose from the options below and click "Generate"</p>
                <div className="shim" />
                <Form onFinish={(f) => onFormFinish(f)}>
                    <Space direction="vertical">
                        {options.map((item, index) => {
                            return (
                                <Form.Item key={index} valuePropName="checked" initialValue={item.checked} name={item.value} noStyle >
                                    <Checkbox onChange={(e) => onChange(!item.checked, index)}  >&nbsp; {item.label}</Checkbox>
                                </Form.Item>
                            )
                        })}

                        {(store?.checked &&
                            <Space>
                                <div>Select store:</div>
                                <Form.Item required={true} valuePropName="checked" initialValue={default_store} name={"store_data_id"} noStyle >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentElement}
                                        showSearch
                                        placeholder={"Select store..."}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                        className='ant-select-with-custom-arrow'
                                        defaultValue={default_store}
                                        style={{ width: 250 }}>
                                        {sharedStores.map(item => {
                                            return (
                                                <Select.Option
                                                    key={item.contact_id}
                                                    value={item.contact_id}
                                                    label={item.name}> {item.name}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Space>
                        )}

                    </Space>
                    <Divider />
                    <div style={{ "float": "right" }}>
                        <Space>
                            <Button onClick={(e) => close()}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Generate</Button>
                        </Space>
                    </div>
                    <br clear="all" />
                </Form>
            </div>
        </>
    )
}