import { Button, Divider, message, Popconfirm, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useState } from "react";

import { apiCall, apiDownload } from "../../utils/Api";

export default function TitlePDF(props) {

    const { isbn } = props;

    //isbn=9780735237759&show_barcode=true&show_marketing=true

    const [showBarcode, setShowBarcode] = useState(false);
    const [showMarketing, setShowMarketing] = useState(false);
    const [visible, setVisible] = useState(false)

    const generatePDF = () => {
        let _f = {
            show_barcodes: showBarcode,
            show_marketing: showMarketing,
            isbns_json: JSON.stringify([isbn]),
            layout: "onebyone",
            is_sans_serif: true,
            is_print_quality: false,
            show_ohoo: false,
            show_ctn_qty: true,
            show_storeprice: true,
            show_listprices: true,
            include_cover: false,
            show_public_comments: false,
            show_private_comments: false,
            show_reviews: false,
            show_biographies: false,
        }

        _f["isbns_json"] = JSON.stringify([isbn]);

        apiCall("title/getPdfOnIsbns", _f, (_status, _result) => {
            if (_status) {
                window.open("/pdf/" + _result.progress_report_id, "_blank");
                setVisible(false);
            } else {
                message.error(_result.error);
                setVisible(false);
            }
        })
    }

    const drawForm = () => {
        return (
            <>
                <div style={{ "fontWeight": "500" }}>Create PDF</div>
                <Divider dashed style={{ "margin": "10px 0px" }} />
                <Space direction="vertical">
                    <Checkbox checked={showBarcode} onChange={() => setShowBarcode(!showBarcode)}>Show Barcode?</Checkbox>
                    {/* <Checkbox checked={showMarketing} onChange={() => setShowMarketing(!showMarketing)}>Show Marketing?</Checkbox> */}
                </Space>
                <div className="shim" />

            </>
        )
    }

    return (
        <Popconfirm
            overlayClassName="popover-noicon"
            title={drawForm()}
            onConfirm={() => generatePDF()}
            okText="Generate"
            cancelText="Cancel"
            onVisibleChange={(e) => setVisible(e)}
            icon={null}
            overlayStyle={{ "padding": "0px" }}
        >
            <Button onClick={() => setVisible(!visible)} style={{ "width": "35px" }} size="small" className="mini-btn" ><small>PDF</small></Button>
        </Popconfirm>
    )
}