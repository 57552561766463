import { AppstoreOutlined, CalendarOutlined, CloseOutlined, MenuOutlined, PicLeftOutlined, QuestionCircleTwoTone, SelectOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Layout, message, Modal, Radio, Select, Space, Spin, Table, Tag, Tooltip, Typography } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';
import { useHistory, useLocation } from "react-router-dom";

import Bookmark from "../components/Bookmark";
import BrowseFilters from "../components/BrowseFilters";
import BrowseShare from "../components/BrowseShare"
import CopyISBNs from "../components/CopyISBNs";
import ExportBrowseExcel from '../components/ExportBrowseExcel';
import ItemLayouts from "../components/ItemLayouts";
import Overlay from '../components/Overlay';
import Paginate from "../components/Paginate";
import Renderer from "../components/Renderer";
import SalesSummary from "../components/SalesSummary";
import BrowseMultiSelect from '../components/title/multi_select/BrowseMultiSelect';
import TitleRank from '../components/title/TitleRank';
import nocover from "../media/no-cover.png";
import conditional from "../tools/conditional";
import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { handleTermSlash, objectToPath, parseSubjectsBisacThreeCode, pathToObject, rawDataColor } from "../utils/Utils";

export default function BrowsePage() {
    const path = "/browse";
    const location = useLocation();
    const history = useHistory();
    const [session, setSession] = useSession();
    const [results, setResults] = useState({ filter_info: [], rows: [], toolboxes: {}, max_offset: 0, row_count: 0 })
    const [catalogueResults, setCatalogueResults] = useState({ rows: [] })
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [display, setDisplay] = useState((Cookies.get("browse_show_list")) ? Cookies.get("browse_show_list") : "details2");
    const [expanded, setExpanded] = useState([]);
    const [searchFilters, setSearchFilters] = useState(pathToObject(location.pathname, "browse_limit"))
    const [current, setCurrent] = useState(parseInt(searchFilters.o / searchFilters.l) + 1);
    const [title, setTitle] = useState("Browse");
    const [initLoaded, setInitLoaded] = useState(false);
    const [vendor, setVendor] = useState({})
    const [catalogueID, setCatalogueID] = useState("");
    const [listLabel, setListLabel] = useState({ key: "", value: "" })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectMultiple, setSelectMultiple] = useState(true);
    const [startingRepFilter, setStartingRepFilter] = useState("");
    const [openSalesHistoryModal, setOpenSalesHistoryModal] = useState(false);
    const [openPeerOHOOModal, setOpenPeerOHOOModal] = useState(false);
    const [openStoreOHOOModal, setOpenStoreOHOOModal] = useState(false);

    const [suppliers, setSuppliers] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [effectiveDates, setEffectiveDates] = useState({ seasons: [], years: [] });
    const [cats, setCats] = useState({ categories: [], subcategories: [] });
    const [tempListMetaData, setTempListMetaData] = useState();
    const [showColumnOptions, setShowColumnOptions] = useState(false);
    const [displayCardAmount, setDisplayCardAmount] = useState(5);

    const listViewRef = useRef(null);

    const initialBriefValues = {
        cover: true,
        isbn: true,
        title: true,
        author: true,
        bd: true,
        list_price: true,
        pubdate: true,
        subjects: true,
        series: false,
        publisher: true,
        rank: true,
        pub_oh_oo: false,
        peer_oh_oo: true,
        sales_history: false,
        print_status: false,
        store_stock: false,
    }

    const [briefDataValues, setBriefDataValues] = useState(initialBriefValues)

    const getStartingOptions = () => {
        setLoading(true);
        let options_results = {};
        apiCall("titlelist/getOptions", {}, (_status, _result) => {
            if (_status){
                setCats({categories: _result.categories, subcategories : _result.subcategories});
                setEffectiveDates(_result.effective_dates);
                setAgencies(_result.agencies);
                setSuppliers(_result.suppliers);
            }
        })
    }

    useEffect(getStartingOptions, [])

    const canSequence = () => {
        if (!searchFilters.hasOwnProperty("x")) {
            return false;
        }
        if (!searchFilters.x) {
            return false;
        }
        return true;
    }

    const getCatalogues = (_change = false, _obj = searchFilters) => {
        setLoading(true);
        let _filters = { ..._obj };
        _filters.filters = JSON.stringify(_filters.filters);
        apiCall("titlelist/getMyCatalogues", _filters, (_status, _result) => {
            if (_status) {
                if (_result.row_count === 1) {
                    if (_obj.hasOwnProperty("t")) {
                        if (!isNaN(_obj.t)) {
                            setListLabel({ key: _result.rows[0].actual_id, value: _result.rows[0].name })
                        }
                    }
                }
                setFilters(_result.filter_info);
                setCatalogueResults(_result);
                setLoading(false);
                setInitLoaded(true);
            }
        })
    }

    const checkCatalogue = () => {
        let m = pathToObject(location.pathname);
        let not_a_catalogue = true;
        if (m.hasOwnProperty("p")) {
            setTitle(<>Browse  <span style={{ "fontWeight": "normal", "color": "#666", "fontSize": "13px" }}>Preset</span></>)
            not_a_catalogue = false;
        } else if (searchFilters.hasOwnProperty("x") && searchFilters.hasOwnProperty("x")) {
            not_a_catalogue = false;
            if (String(searchFilters.x).substring(0, 4) === "temp") {
                // Temp List
                apiCall("titlelist/getTempListMeta", { list_id: searchFilters.x }, (_status, _result) => {
                    if (_status) {
                        setTempListMetaData(_result);
                        setTitle(<div>Browsing: <span style={{ "color": "#666", "fontSize": "20px" }}>{_result.name}</span></div>);
                    }
                })
            } else {
                // Regular List
                apiCall("titlelist/get", { list_id: searchFilters.x }, (_status, _result) => {
                    if (_status) {
                        setCatalogueID(_result.list_id);
                        setListLabel({ [searchFilters.x]: _result.name });
                        setVendor(_result.vendor);
                        let _pth = (_result.is_catalogue) ? "/catalogues/public-catalogues/filter/t/" : "/catalogues/my-catalogues/filter/t/"
                        setTitle(
                            <div
                                style={{ "cursor": "pointer" }}
                                onClick={() => window.open(_pth + _result.actual_id, "_blank")}>
                                Browsing {(_result.is_catalogue && _result.shared_status === "everyone") ? " Public " : "My "} Catalogue:
                                <span style={{ "color": "#666", "fontSize": "13px" }}> {_result.name}
                                    ({_result.isbns_count} titles) &nbsp;
                                    <SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "10px" }} />
                                </span>
                            </div>
                        )
                    }
                })
            }
        } else if (searchFilters.hasOwnProperty("k") && searchFilters.k === "series") {
            not_a_catalogue = false;
            let _term = searchFilters.hasOwnProperty("t") ? searchFilters.t : "";
            setTitle(<>Browse  <span style={{ "fontWeight": "normal", "color": "#666", "fontSize": "13px" }}> - {_term} (series)</span></>)
        } else if (not_a_catalogue) {
        }
    }

    useEffect(checkCatalogue, [searchFilters])

    const setSupplier = (_supplier) => {
        if (_supplier !== "default") {
            setBriefDataValues({ ...briefDataValues, "pub_oh_oo": true })
            apiCall("session/setSupplier", { supplier: _supplier }, (_status, _result) => {
                if (_status) {
                    setSession(_result);
                    getBrowse();
                }
            })
        } else {
            setBriefDataValues({ ...briefDataValues, "pub_oh_oo": false })
        }
    }

    const getBrowse = (_change = false, _obj = searchFilters, _route = true) => {
        if (loading) { return; }
        setLoading(true);
        if (listViewRef.current) {
            listViewRef.current.scrollTop = 0;
        }
        let _filters = { ..._obj }
        if (_filters["t"]) {
            _filters["t"] = _filters["t"].replace("%25", "%");
            _filters["t"] = _filters["t"].replace("%2f", "/");
        }
        if (_change) {
            _filters["click_info"] = JSON.stringify(_change);
        }
        apiCall("browse/get", _filters, (_status, _result) => {
            if (_status) {
                setFilters(_result.filter_info);
                setResults(_result);
                setExpanded([]);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                if(_change === true){
                    setSelectedRowKeys([])
                }
                forceCheck();
                if (_change) {
                    updateFilters(_result.filter_info, _route);
                } else {
                    setLoading(false);
                }
                setInitLoaded(true);
            }
        });
    }

    const [flag, setFlag] = useState(false);
    const routeFilters = (_sf, _route) => {
        let _filter_path = objectToPath(_sf);
        if (_route) {
            setFlag(true);
            history.push({ pathname: path + _filter_path })
        }
        setLoading(false);
    }

    const updateFilters = (_arr, _route, o = searchFilters.o, l = searchFilters.l, t = searchFilters.t, k = searchFilters.k, v = searchFilters.v) => {
        let _sf = {}
        _arr.forEach(element => {
            if (element.selected_rows.length > 0) {
                _sf[element.routing_char] = (element.exclude) ? element.selected_rows.map(itm => "-" + itm) : element.selected_rows;
            }
        });
        _sf.o = o;
        _sf.l = l;
        _sf.t = t;
        _sf.k = k;
        _sf.v = v;
        setSearchFilters(_sf);
        if (_route) {
            routeFilters(_sf, _route);
        } else {
            setLoading(false);
        }
    }

    // Call on back/forward button
    const [refresh, setRefresh] = useState(false)
    const onPopState = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        window.addEventListener('popstate', onPopState);
        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, []);

    const headerSearch = () => {
        setLoading(true);
        let obj = pathToObject(location.pathname, "browse_limit");
        setTitle("Browse")
        setSearchFilters(obj);
        getBrowse(false, obj);
    }

    useEffect(headerSearch, [history.location.state])

    const updateSearchWithin = () => {
        let obj = pathToObject(location.pathname, "browse_limit");
        if (obj.hasOwnProperty("t") && (obj.t)) {
            form.setFieldsValue({ "customer_search": handleTermSlash(obj.t, false) });
        }
    }
    useEffect(updateSearchWithin, [history.location.pathname])

    const colorDate = (_timestamp, values) => {
        let now = moment().unix();
        let color = "inherit";
        if (_timestamp > now) {
            // future
            color = "futureColor";
        } else {
            // within the last two months
            if ((now - _timestamp) < 5274000) {
                // within the last two months
                color = "rowRetn";
            }
        }
        if (values.highlight) {
            return color;
        }
        return color;
    }

    const noCover = (e) => {
        e.target.src = nocover;
        e.target.onerror = null;
    }

    function toggleMultiSelect(id, checked) {
        let tempSelectedKeys = [...selectedRowKeys]
        if (checked) {
            tempSelectedKeys.push(id);
        } else {
            tempSelectedKeys.splice(tempSelectedKeys.indexOf(id), 1);
        }
        setSelectedRowKeys(tempSelectedKeys);
    }

    function checkIfSelected(row_data) {
        let is_selected = false;
        if (selectedRowKeys.some((row) => row === row_data.isbn)) {
            is_selected = true;
        }
        return is_selected;
    }

    const showSalesHistoryInfoModal = () => {
        setOpenSalesHistoryModal(true);
    }

    const closeSalesHistoryInfoModal = () => {
        setOpenSalesHistoryModal(false);
    }

    const showPeerOHOOInfoModal = () => {
        setOpenPeerOHOOModal(true);
    }

    const closePeerOHOOInfoModal = () => {
        setOpenPeerOHOOModal(false);
    }

    const showStoreOHOOInfoModal = () => {
        setOpenStoreOHOOModal(true);
    }

    const closeStoreOHOOInfoModal = () => {
        setOpenStoreOHOOModal(false);
    }

    function getSubjectsTooltip(e) {
        if (e === null || e.length === 0) {
            return ""
        }
        return e[0].subject;
    }

    const columns = []

    if (briefDataValues.cover) {
        columns.push({
            title: <small>Cover</small>, dataIndex: 'eisbn', key: 'eisbn', render: (e, f) => {
                return <div style={{ "height": "45px", "overflow": "hidden" }}>
                    {((!canSequence()) && <div style={{ "marginLeft": "12px", "paddingTop": "-2px" }}><Bookmark index={0} searchFilters={searchFilters} isbn={f.isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} /></div>)}
                    <img className="shadow-large" onError={(e) => noCover(e)} style={{ "width": "30px", "marginRight": "10px" }} src={"https://bookmanager.com/i/b?b=" + e} alt="" />
                </div>;
            }
        })
    }

    if(briefDataValues.store_stock){
        columns.push({
            title: <small>Store OH/OO <QuestionCircleTwoTone onClick={(e) => showStoreOHOOInfoModal()} /></small>, width: '100px', dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                if(f.sales.length !== 0){
                    return (
                        <Space size={2}>
                            {getStoreStockFromSales(f.sales[0].sales, true, f.highlight)}
                            {getStoreStockFromSales(f.sales[0].sales, false, f.highlight)}
                            <small
                                className="rowReceived"
                                style={{
                                    "fontSize": "12px",
                                    "whiteSpace": "nowrap"
                                }}>{f.sales[0].sales.cart_qty || ""}
                            </small>
                        </Space>
                    );
                }
                return (
                    <></>
                );
            }
        })
    }

    if (briefDataValues.peer_oh_oo) {
        columns.push({
            title: <small>Peer OH/OO <QuestionCircleTwoTone onClick={(e) => showPeerOHOOInfoModal()} /></small>, width: '100px', dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                return (
                    <Space
                        size={0}>
                        {getPeerFromSales(f.sales_summary, true, f.highlight)}
                        {getPeerFromSales(f.sales_summary, false, f.highlight)}
                    </Space>
                );
            }
        })
    }

    if (briefDataValues.isbn) {
        columns.push({
            title: <small>ISBN</small>, dataIndex: 'isbn', key: 'isbn'
        })
    }

    if (briefDataValues.title) {
        columns.push({
            title: <small>Title</small>, dataIndex: 'title', key: 'title', render: (e, f) => {
                return <div className='briefTitleTruncate' style={{ "cursor": "pointer" }} onClick={() => expandCell(f)}>{e}{(f.subtitle) ? " : " + f.subtitle : ""}</div>
            }
        })
    }



    if (briefDataValues.author) {
        columns.push({
            title: <small>Author</small>, dataIndex: 'authors', key: 'authors', render: (e) => {
                if (e[0] !== null && e[0] !== undefined) {
                    return <div className="truncate">{e[0].name}</div>
                }
                // return e.map(item => item.name).join(",");
            }
        })
    }

    if (briefDataValues.bd) {
        columns.push({
            title: <small>Bd</small>, dataIndex: 'binding_code', key: 'binding_code'
        })
    }

    if (briefDataValues.list_price) {
        columns.push({
            title: <small><nobr>List Price</nobr></small>, width: '55px', dataIndex: 'price', key: 'price', render: (e, f) => {
                return (
                    <>
                        <div style={{ "fontWeight": "normal" }}>{(f.price > 0) ? "$" + f.price : ""}&nbsp;</div>
                    </>
                );
            }
        })
    }

    if (briefDataValues.pubdate) {
        columns.push({
            title: <small>PubDate</small>, dataIndex: 'release_date', key: 'release_date', render: (e, f) => {
                return <nobr><span className={colorDate(e, f)} >{moment(e * 1000).format("MMMDD-YY")}</span></nobr>;
            }
        })
    }

    if (briefDataValues.subjects) {
        columns.push({
            title: <small>Subjects</small>, dataIndex: 'subjects', key: 'subjects', render: (e, f) => {
                return <Tooltip title={getSubjectsTooltip(e)}>
                    {parseSubjectsBisacThreeCode(e)}
                </Tooltip>;

            }
        })
    }

    if (briefDataValues.series) {
        columns.push({
            title: <small>Series</small>, dataIndex: 'series', key: 'series', render: (e, f) => {
                if (f.series !== null) {
                    return <>
                        <div className='truncate'><small>{f.series_num}</small> {f.series}</div>
                    </>
                }
                return <>
                    <div className='truncate'>{f.series}</div>
                </>
            }
        })
    }

    if (briefDataValues.publisher) {
        columns.push({
            title: <small>Publisher</small>, dataIndex: 'publisher', key: 'publisher', render: (e) => {
                return <div className='truncate'>{e}</div>
            }
        })
    }

    if (briefDataValues.rank) {
        columns.push({
            title: <small>Rank</small>, align: 'center', dataIndex: 'rank', key: 'rank', render: (e, f) => {
                return <TitleRank simple rank={e} />
            }
        })
    }

    if (briefDataValues.pub_oh_oo) {
        //commentted out until pubstock is included
        columns.push({
            title: <small><nobr>PubOH</nobr></small>, dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                return (
                    <Space
                        size={0}>
                        {getPeerFromSales(f.supplier_info, true, f.highlight)}
                    </Space>
                );
            }
        })

        columns.push({
            title: <small>PubOO</small>, dataIndex: 'onhand', key: 'onhand', render: (e, f) => {
                return (
                    <Space
                        size={0}>
                        {getPeerFromSales(f.supplier_info, false, f.highlight)}
                    </Space>
                );
            }
        })
    }

    if (briefDataValues.print_status) {
        columns.push({
            title: <small>Print Status</small>,
            dataIndex: 'print_status',
            key: 'print_status'
        })
    }

    if (briefDataValues.sales_history) {
        columns.push({
            title: <small>Sales History <QuestionCircleTwoTone onClick={(e) => showSalesHistoryInfoModal()} /></small>,
            dataIndex: 'sales',
            width: 160,
            key: 'sales',
            render: (e, f) => {
                if (f.hasOwnProperty("sales_summary") && Object.keys(f.sales_summary).length > 0) {
                    return (
                        <SalesSummary conditions={f} highlight={f.highlight} />
                    );
                }
                return (
                    <small className="c">
                        N/A
                    </small>
                )
            }
        },)
    }

    if (canSequence()) {
        columns.unshift({
            title: <small>#</small>, width: "20px", align: "center", dataIndex: 'eisbn', key: 'eisbn', render: (e, f, g) => {
                return (
                    <div style={{ "width": "20px" }} className='overhide'>
                        <div style={{ "marginLeft": "9px", "marginTop": "-28px", "paddingTop": "-2px" }}>
                            <Bookmark index={0} searchFilters={searchFilters} isbn={f.isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} /></div>
                        <div>
                            <small>{f.sequence_num}</small>
                        </div>
                    </div>
                );
            }
        })
    }

    //need to move this outside the table
    if (selectMultiple) {
        columns.unshift({
            title: <small></small>, width: '0px', render: (e, f) => {
                return (<div style={{ "marginLeft": "-65px", "position": "relative" }}><div style={{ "marginLeft": "0px", "position": "absolute", "top": "-10px" }}>Select <Checkbox checked={checkIfSelected(f)} onClick={(e) => toggleMultiSelect(f.isbn, e.target.checked)} /></div></div>)
            }
        },)
    }

    const convertSupplierCode = (_qty = "") => {
        if (!_qty) {
            return "";
        }
        if (typeof _qty === "string") {
            _qty = _qty.trim();
            _qty = _qty.toUpperCase();
        }
        if (_qty === 'T') {
            return "TOS";
        } else if (_qty === 'Y') {
            return "YES";
        } else if (_qty === '+') {
            return "NYR";
        } else if (_qty === "Q") {
            return "Qwk";
        } else if (_qty === "L") {
            return "LOW";
        } else if (_qty === "Z") {
            return "ITO";
        } else if (_qty === "R") {
            return "RP";
        } else if (_qty === "P") {
            return "POD";
        } else if (_qty === "M") {
            return "Mgd";
        } else if (_qty === 'O') {
            return 'BOO';
        }
        return _qty;
    }

    const getPeerFromSales = (_sales = {}, _ohhand = true, highlight = false) => {
        let {
            onhand = "",
            onhandlocs = "",
            onorder = "",
            onorderlocs = "",
        } = _sales;
        if (_ohhand) {
            return <small
                className="rowRawData"
                style={{
                    "fontSize": "12px",
                    "whiteSpace": "nowrap"
                }}>{convertSupplierCode(onhand) || ""}<sup> {onhandlocs || ""} </sup>&nbsp;</small>;
        } else {
            return <small
                className="rowRawData"
                style={{
                    "fontSize": "12px",
                    "whiteSpace": "nowrap"
                }}>{convertSupplierCode(onorder) || ""}<sup> {onorderlocs || ""}</sup>&nbsp;</small>;
        }
    }

    const getStoreStockFromSales = (_sales = {}, _ohhand = true, highlight = false) => {
        let {
            total_onhand = "",
            available_onorder = "",
        } = _sales;
        if (_ohhand) {
            return <small
                className="noDesc"
                style={{
                    "fontSize": "12px",
                    "whiteSpace": "nowrap"
                }}>{convertSupplierCode(total_onhand) || ""} &nbsp; </small>;
        } else {
            return <small
                className="warningColor"
                style={{
                    "fontSize": "12px",
                    "whiteSpace": "nowrap"
                }}>{convertSupplierCode(available_onorder) || ""} &nbsp; </small>;
        }
    }

    const drawChildTable = (_record) => {
        return (
            <>
                <div className="hideHover" style={{ "marginTop": "-4px", "marginLeft": "-8px" }}>
                    <div style={{ "padding": "0 20px" }}>
                        <div style={{ "marginTop": "0px", "borderTop": "none" }} className="closebar">
                            <Button style={{ "float": "right" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
                            <br clear="both" />
                        </div>
                    </div>
                    <div style={{ "padding": "0px 20px" }}>
                        <Renderer close={false}><ItemLayouts catalogueID={catalogueID} data={_record} display="details2" /></Renderer>
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>
            </>
        )
    }

    const expandCell = (_data, _type) => {
        if (!expanded.includes(_data.eisbn)) {
            setExpanded([_data.eisbn]);
        } else {
            setExpanded([]);
        }
    }

    const displayGroup = () => {
        return (
            <div style={{ "marginTop": "-7px" }}>
                <Radio.Group
                    size="small"
                    value={display}
                    onChange={(e) => {
                        Cookies.set("browse_show_list", e.target.value);
                        if (catalogueID !== "") {
                            getBrowse();
                        }
                        setDisplay(e.target.value)
                    }}
                    buttonStyle="solid">
                    <Radio.Button value={"details2"}>
                        <Tooltip title={"Detailed View"}>
                            <small><PicLeftOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                    <Radio.Button value={"list"}>
                        <Tooltip title={"Brief View"}>
                            <small><MenuOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                    <Radio.Button value={"cards"}>
                        <Tooltip title={"Covers View"}>
                            <small><AppstoreOutlined /></small>
                        </Tooltip>
                    </Radio.Button>
                    <Radio.Button value={"calendar"}>
                        <Tooltip title={<small>Compare previous edition side-by-side. <br />(Best for calendar purchasing)</small>}><small><CalendarOutlined /></small></Tooltip>
                    </Radio.Button>
                </Radio.Group>
            </div>
        )
    }

    const [form] = Form.useForm();

    const SearchWithin = () => {
        const [val, setVal] = useState((searchFilters.hasOwnProperty("t") ? searchFilters.t : ""));
        useEffect(() => {
            setVal(searchFilters.t)
        }, [searchFilters.t])
        return (
            <Form.Item noStyle initialValue={(searchFilters.hasOwnProperty("t") ? searchFilters.t : "")} label={<small>Find a list</small>}>
                <Input.Search size="small" onChange={(e) => setVal(e.target.value)} onSearch={(e, f) => {
                    setVal(e);
                    if (!e && f.type !== "click" && f.type !== "keydown") {
                        return;
                    }
                    e = handleTermSlash(e, true)
                    let sf = { ...searchFilters, "t": e, "o": 0 }
                    setSearchFilters(sf);
                    getBrowse(false, sf);
                    setCurrent(1);
                    history.push({ pathname: path + objectToPath(sf) });
                }} allowClear value={val} enterButton placeholder="Search within..." style={{ "width": "324px" }} />
            </Form.Item>
        )
    }

    //From the backend, presets are now "synthetically" set if you don't have a different default set
    const drawFilters = () => {
        return (
            <div className="layoutHeader" style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "10px 20px", "paddingTop": "0px" }}>
                <Form form={form} layout="vertical">
                    <Space wrap size={[5, 0]}>
                        <SearchWithin />
                        <Form.Item noStyle label={<small>Sort</small>}>
                            <Select
                                className='ant-select-with-custom-arrow'
                                value={(searchFilters.v) ? searchFilters.v : ""}
                                getPopupContainer={trigger => trigger.parentElement}
                                size="small"
                                onChange={(e) => {
                                    let _f = { ...searchFilters, "v": e }
                                    setSearchFilters(_f);
                                    getBrowse(false, _f);
                                    history.push({ pathname: path + objectToPath(_f) })
                                }}
                                style={{ width: 110 }}>
                                {((searchFilters.hasOwnProperty("x") && (searchFilters)) && <Select.Option key="sequence" value="sequence"><small>List Order</small></Select.Option>)}
                                <Select.Option key="popularity" value=""><small>Popularity</small></Select.Option>
                                <Select.Option key="instore" value="in_store"><small>In Store</small></Select.Option>
                                <Select.Option key="date" value="date" ><small>Date</small></Select.Option>
                                <Select.Option key="title" value="title" ><small>Title</small></Select.Option>
                                <Select.Option key="author" value="author" ><small>Author</small></Select.Option>
                                {((searchFilters.v && searchFilters.v.substring(0, 7) === "related") && <Select.Option key={searchFilters.v} value={searchFilters.v} ><small>Related</small></Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle label={<small>ISBNs</small>}>
                            <CopyISBNs searchFilters={searchFilters} />
                        </Form.Item>
                        <Form.Item noStyle label={<small>ISBNs</small>}>
                            <ExportBrowseExcel row_count={results.row_count} searchFilters={searchFilters} />
                        </Form.Item>
                    </Space>
                    <div className='shim' />
                    <div className='float-flex'>
                        <Space >
                            <Button disabled={(selectedRowKeys.length === 0)} onClick={() => setSelectedRowKeys([])} size='small' danger type='primary'>Clear Selected</Button>
                            <Button type='primary' size='small' disabled={selectedRowKeys.length === 0}>
                                <Overlay 
                                    width={1100}
                                    component={
                                        <BrowseMultiSelect
                                            available_corps={session.available_corps}
                                            catalogueID={catalogueID}
                                            selectedRowKeys={selectedRowKeys}
                                            setSelectedRowKeys={setSelectedRowKeys}
                                            selectMultiple={selectMultiple}
                                            titles={results.rows}
                                            suppliers={suppliers}
                                            agencies={agencies}
                                            categories={cats}
                                            effectiveDates={effectiveDates}
                                            results={results}
                                            setResults={setResults}
                                            toggleMultiSelect={toggleMultiSelect}
                                            toggleSelectMultiple={toggleSelectMultiple}
                                            resetSelection={resetSelection}
                                            multiSelectRefresh={getBrowse}
                                        />
                                    }
                                ><small>Select Tools ({selectedRowKeys.length} selected)</small>
                                </Overlay>
                            </Button>
                            <conditional.true key={"list"} value={(display === "list")}>
                                <Button type='primary' size='small' onClick={(e) => setShowColumnOptions(!showColumnOptions)}><small>{showColumnOptions ? "Hide Column Options" : "Show Column Options"}</small></Button>
                            </conditional.true>
                        </Space>
                        <conditional.true key={"cards"} value={(display === "cards")}>
                            <Radio.Group defaultValue={displayCardAmount} onChange={(e) => { setDisplayCardAmount(e.target.value); }} size='small' optionType="button" buttonStyle="solid" >
                                <Radio.Button value={5}><small>Large Covers</small></Radio.Button>
                                <Radio.Button value={10}><small>Small Covers</small></Radio.Button>
                            </Radio.Group>
                        </conditional.true>
                    </div>
                </Form>
            </div>
        )
    }

    function rowClasses(record) {
        if (record.highlight) {
            return "rowHighlightHover defaultCursor highlightRow"
        }
        return "rowHover defaultCursor"
    }

    const expandable = {
        expandedRowKeys: expanded,
        expandIconColumnIndex: -1,
        onExpand: (e) => { return false },
        expandIcon: <></>,
        expandedRowRender: (record) => drawChildTable(record)
    };

    const drawListTable = () => {
        return (
            <div style={{ "margin": "0 -20px" }}>
                <div>
                    <Table
                        size="small"
                        pagination={false}
                        columns={columns}
                        // scroll={{ y: 550 }}
                        dataSource={results.rows}
                        rowKey={(record) => record.eisbn}
                        rowClassName={(record, index) => rowClasses(record, index)} //the problem with dark mode
                        className="fullTable"
                        expandable={expandable}
                    />
                </div>
            </div>
            // </div>
        )
    }

    const toggleSelectMultiple = () => {
        setSelectMultiple((current) => !current);
    }

    const resetSelection = () => {
        if (selectedRowKeys.length > 0) {
            setSelectedRowKeys([]);
            message.info("Successfully reset all selections!")
        } else {
            message.error("No selections were cleared, as no selections existed")
        }
    }

    function checkIfHighlighted(highlighted) {
        if (highlighted) {
            return {
                borderStyle: "solid",
                borderColor: "#ff0099",
                borderWidth: "3px",
                paddingTop: "10px",
            }
        }
    }

    function displayBottomLine(index) {
        const bottom_line = <div className="borderBottom shim" style={{ "margin": "35px -20px", "borderBottomWidth": "6px" }} />
        if (results.rows.length !== (index + 1)) {
            return bottom_line;
        }
    }

    function showStoreColumns(){
        if(session.stores_working_with.length > 0 && session.stores_working_with.length < 2){
            return <Checkbox defaultChecked={briefDataValues.store_stock} onChange={(e) => setBriefDataValues({ ...briefDataValues, "store_stock" : e.target.checked })}>Show {session.stores_working_with[0].name}'s Stock</Checkbox>
        }else if(session.stores_working_with.length >= 2){
            let return_object = session.stores_working_with.map((store) => {
                return (<Checkbox defaultChecked={briefDataValues.store_stock} onChange={(e) => setBriefDataValues({ ...briefDataValues, "store_stock" : e.target.checked })}>Show Store Stock</Checkbox>)
            })
            return return_object;
        }
        return <></>
    }

    return (
        <>
            <Layout className="layout" style={{ "minHeight": "500px" }}>
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    <div style={{ "paddingBottom": "0px", "margin": "0px 0px", "padding": "20px 20px 0px 20px" }}>
                        <div style={{ "float": "right" }}>{displayGroup()}</div>
                        <Typography.Title level={4}>
                            <Space>
                                <div>{title}</div>
                                <Button size='small' type='primary'>
                                    <Overlay maskCloseable={false} width={800} component={
                                        <BrowseShare searchFilters={searchFilters} />
                                    }><small>Share browse</small>
                                    </Overlay>
                                </Button>
                            </Space>
                        </Typography.Title>
                        <div className="shim" />
                    </div>
                    {(initLoaded && <>
                        <BrowseFilters
                            block
                            setTitle={setTitle}
                            listLabel={listLabel}
                            loading={loading}
                            getBrowse={getBrowse}
                            toolboxes={results.toolboxes}
                            startingRepFilter={startingRepFilter}
                            tempListMetaData={tempListMetaData}
                            path={path}
                            setCurrent={setCurrent}
                            filters={filters}
                            setFilters={setFilters}
                            searchFilters={searchFilters}
                            setSearchFilters={setSearchFilters} />
                        <div className="trans" style={{ "opacity": (loading) ? "0.5" : 1 }}> {drawFilters()} </div>
                    </>)}
                    <conditional.true key={"list"} value={showColumnOptions}>
                        <Space style={{ "marginLeft": "20px" }}>
                            <Tooltip title={"Choose a Pubstock supplier to display their OH/OO info below"}>
                                <Select defaultValue={"default"} showSearch size="small" onChange={(e) => setSupplier(e)} value={session.chosen_supplier} style={{ width: 130 }}>
                                    <Select.Option value="default"><small style={{ "color": "#bbb" }}>Show Pubstock</small></Select.Option>
                                    {suppliers.map(item => {
                                        return <Select.Option key={item.acct} value={item.acct}><small>{item.acct}</small></Select.Option>
                                    })}
                                </Select>
                            </Tooltip>
                            <Checkbox defaultChecked={briefDataValues.series} onChange={(e) => setBriefDataValues({ ...briefDataValues, "series": e.target.checked })}>Show Series</Checkbox>
                            <Checkbox defaultChecked={briefDataValues.sales_history} onChange={(e) => setBriefDataValues({ ...briefDataValues, "sales_history": e.target.checked })}>Show Sales History</Checkbox>
                            <Checkbox defaultChecked={briefDataValues.print_status} onChange={(e) => setBriefDataValues({ ...briefDataValues, "print_status": e.target.checked })}>Show Print Status</Checkbox>
                            {/* Store stock should only show up when a store is selected */}
                            {showStoreColumns()}
                        </Space>
                        <br />
                    </conditional.true>
                    <Paginate max_offset={results.max_offset} save={"browse_limit"} loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getBrowse(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />
                    <Spin style={{ "minHeight": "calc(100vh - 470px)" }} spinning={loading}>
                        <div className="bcg2" style={{ "padding": "0px 20px", "minHeight": "calc(100vh - 440px)" }}>
                            <Modal title="Sales History Information" visible={openSalesHistoryModal} onOk={() => closeSalesHistoryInfoModal()} onCancel={() => closeSalesHistoryInfoModal()}>
                                <div className='bc'>
                                    Sales data is collected weekly from indie bookstores across the country. These stats represent actual raw data from store peers, and are not weighted in any way. (FYI: The peer data that stores see is weighted). Our ranking engine uses these numbers in a weighted formula to better represent relative performance.
                                    <br />
                                    <br />
                                    LOC = number of stores that have sold this book in the last 14 months (including current month)
                                    <br />
                                    <br />
                                    Calendar months = recent 3 month sales snapshot
                                    <br />
                                    <br />
                                    Wk = Total number sold in the most recent week
                                </div>
                            </Modal>
                            <Modal title="Store OH/OO Information" visible={openStoreOHOOModal} onOk={() => closeStoreOHOOInfoModal()} onCancel={() => closeStoreOHOOInfoModal()}>
                                <div className='bc'>
                                    The individual stores data, showing the total onhand, onorder, and in their cart on biz.bookmanager.com
                                    <br />
                                    <br />
                                    Green number = OH
                                    <br />
                                    OH = Total number of copies currently on hand, both available and on hold.
                                    <br />
                                    <br />
                                    Orange number: OO
                                    <br />
                                    OO = Total number of copies currently on order.
                                    <br />
                                    <br />
                                    Blue number: cart values for how many copies the store has in their cart
                                </div>
                            </Modal>
                            <Modal title="Peer OH/OO Information" visible={openPeerOHOOModal} onOk={() => closePeerOHOOInfoModal()} onCancel={() => closePeerOHOOInfoModal()}>
                                <div className='bc'>
                                    Current on hand and on order data is collected daily from indie bookstores across the country. These stats represent actual raw data from store peers, and are not weighted in any way. (FYI: The peer data that stores see is weighted). Our ranking engine uses these numbers in a weighted formula to better represent relative performance.
                                    <br />
                                    <br />
                                    OH = Total number of copies currently on hand among all stores, with the number of stores having stock shown in superscript.
                                    <br />
                                    <br />
                                    OO = Total number of copies currently on order among all stores, with the number of stores having it on order shown in superscript.
                                </div>
                            </Modal>
                            
                            <conditional.true value={(display === "calendar")}>
                                <div style={{ "display": "flex", "justifyContent": "space-around" }}>
                                    <div>
                                        <small>Current Edition</small>
                                    </div>
                                    <div>
                                        <small>Previous Edition</small>
                                    </div>
                                </div>
                                <Divider style={{ "margin": "0px" }} />
                            </conditional.true>
                            <conditional.true key={"list"} value={(display === "list")}>
                                {drawListTable()}
                            </conditional.true>
                            <conditional.true key={"notlist"} value={(display !== "list")}>
                                <br />
                                {/* Split into chunks so renderer can draw placeholder */}
                                <conditional.true key={"cards"} value={(display === "cards")}>
                                    <div>
                                        {results.rows.reduce((all, one, i) => {
                                            const ch = Math.floor(i / displayCardAmount);
                                            all[ch] = [].concat((all[ch] || []), one);
                                            return all
                                        }, []).map((item, index) => {
                                            return <div key={item[0].isbn + "cards" + displayCardAmount}>
                                                <Bookmark index={index} searchFilters={searchFilters} isbn={item[0].isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} />
                                                <Renderer>
                                                    <ItemLayouts
                                                        cardDisplayClassName={(displayCardAmount === 5) ? "cardgrid" : "smallcardgrid"}
                                                        selectedRowKeys={selectedRowKeys}
                                                        data={item}
                                                        filters={filters}
                                                        index={index}
                                                        display={display}
                                                        selectMultiple={selectMultiple}
                                                        toggleMultiSelect={toggleMultiSelect}
                                                        suppliers={suppliers}
                                                        effectiveDates={effectiveDates}
                                                        agencies={agencies}
                                                        categories={cats}
                                                    />
                                                </Renderer>
                                                <Divider dashed />
                                            </div>
                                        })}
                                    </div>
                                </conditional.true>
                                <conditional.true key={"notcards"} value={(display !== "cards")}>
                                    <div>
                                        {results.rows.map((item, index) => {
                                            return (
                                                <div key={item.eisbn + "notcards"} >
                                                    <div style={{"position" : "relative", "top" : "-20px", "left" : "7px"}}><Bookmark index={index} searchFilters={searchFilters} isbn={item.isbn} list_id={(searchFilters.hasOwnProperty("x") ? searchFilters.x : "")} /></div>
                                                    <conditional.true value={selectMultiple}>
                                                        <div style={{ "marginLeft": "-85px", "marginBottom": "-23px" }}>
                                                            Select
                                                            <Checkbox checked={checkIfSelected(item)} style={{ "paddingLeft": "5px" }} onChange={(e) => toggleMultiSelect(item.isbn, e.target.checked)}>  </Checkbox>
                                                        </div>
                                                    </conditional.true>
                                                    <LazyLoad offset={500} once={true} height={360}>
                                                        <div key={item.eisbn + "ll"} style={checkIfHighlighted(item.highlight)}>
                                                            <Renderer close={false}>
                                                                <ItemLayouts
                                                                    selectedRowKeys={selectedRowKeys}
                                                                    catalogueID={catalogueID}
                                                                    vendor={vendor}
                                                                    filters={filters}
                                                                    data={item}
                                                                    display={display}
                                                                    suppliers={suppliers}
                                                                    effectiveDates={effectiveDates}
                                                                    agencies={agencies}
                                                                    categories={cats}
                                                                />
                                                            </Renderer>
                                                        </div>
                                                        {(displayBottomLine(index))}
                                                    </LazyLoad>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </conditional.true>
                            </conditional.true>
                        </div>
                    </Spin>
                    <Divider className="bc" style={{ "margin": "0" }} />
                    <Paginate max_offset={results.max_offset} save={"browse_limit"} loading={loading} paginate={{ current: current, offset: (searchFilters.hasOwnProperty("o")) ? searchFilters.o : 0, pagesize: (searchFilters.hasOwnProperty("l")) ? searchFilters.l : 20 }} setPaginate={(e) => {
                        setCurrent(e.current);
                        let sf = { ...searchFilters, "o": e.offset, "l": e.pagesize }
                        setSearchFilters(sf);
                        getBrowse(false, sf);
                        history.push({ pathname: path + objectToPath(sf) });
                    }} count={results.row_count} />
                </Layout.Content>
            </Layout>
        </>
    )
}